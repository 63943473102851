// Resources
export const LOAD_RESOURCE = 'LOAD_RESOURCE';
export const LOAD_RESOURCES = 'LOAD_RESOURCES';
export const SELECT_RESOURCE = 'SELECT_RESOURCE';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';

// Sermons
export const LOAD_SERMON = 'LOAD_SERMON';
export const LOAD_SERMONS = 'LOAD_SERMONS';
export const SELECT_SERMON = 'SELECT_SERMON';
export const CREATE_SERMON = 'CREATE_SERMON';
export const UPDATE_SERMON = 'UPDATE_SERMON';
export const DELETE_SERMON = 'DELETE_SERMON';
