import * as React from 'react';
import { Helmet } from 'react-helmet';

export const PrivacyPolicyPage = () => (
  <React.Fragment>
    <Helmet>
      <title>Privacy Policy | Shalom Friend</title>
    </Helmet>
    <div className="container-fluid" style={{ padding: 25 }}>
      <h1>Privacy Policy</h1>
      <p>
        Protecting your private information is our priority. This Statement of
        Privacy applies to shoreshdavidbrandon.org and Shoresh David Messianic
        Synagogue of Brandon Inc. and governs data collection and usage. For the
        purposes of this Privacy Policy, unless otherwise noted, all references
        to Shoresh David Messianic Synagogue of Brandon Inc. include
        shoreshdavidbrandon.org and Shalom Friend. The Shoresh David
        Brandon website is a synagogue website site. By using the Shoresh David
        Brandon website, you consent to the data practices described in this
        statement.
      </p>
      <h3>Collection of your Personal Information</h3>
      <p>
        In order to better provide you with products and services offered on our
        Site, Shalom Friend may collect personally identifiable
        information, such as your: – First and Last Name – Mailing Address –
        E-mail Address – Phone Number If you purchase Shalom Friend’s
        products and services, we collect billing and credit card information.
        This information is used to complete the purchase transaction. We do not
        collect any personal information about you unless you voluntarily
        provide it to us. However, you may be required to provide certain
        personal information to us when you elect to use certain products or
        services available on the Site. These may include: (a) registering for
        an account on our Site; (b) entering a sweepstakes or contest sponsored
        by us or one of our partners; (c) signing up for special offers from
        selected third parties; (d) sending us an email message; (e) submitting
        your credit card or other payment information when ordering and
        purchasing products and services on our Site. To wit, we will use your
        information for, but not limited to, communicating with you in relation
        to services and/or products you have requested from us. We also may
        gather additional personal or non-personal information in the future.
      </p>
      <h3>Use of your Personal Information</h3>
      <p>
        Shalom Friend collects and uses your personal information to
        operate its website(s) and deliver the services you have requested.
        Shalom Friend may also use your personally identifiable
        information to inform you of other products or services available from
        Shalom Friend and its affiliates.
      </p>
      <h3>Sharing Information with Third Parties</h3>
      <p>
        Shalom Friend does not sell, rent or lease its customer lists to
        third parties. Shalom Friend may, from time to time, contact you
        on behalf of external business partners about a particular offering that
        may be of interest to you. In those cases, your unique personally
        identifiable information (e-mail, name, address, telephone number) is
        not transferred to the third party. Shalom Friend may share data
        with trusted partners to help perform statistical analysis, send you
        email or postal mail, provide customer support, or arrange for
        deliveries. All such third parties are prohibited from using your
        personal information except to provide these services to Shoresh David
        Brandon, and they are required to maintain the confidentiality of your
        information.
      </p>
      <p>
        Shalom Friend may disclose your personal information, without
        notice, if required to do so by law or in the good faith belief that
        such action is necessary to: (a) conform to the edicts of the law or
        comply with legal process served on Shalom Friend or the site;
        (b) protect and defend the rights or property of Shalom Friend;
        and/or (c) act under exigent circumstances to protect the personal
        safety of users of Shalom Friend, or the public.
      </p>
      <h3>Automatically Collected Information</h3>
      <p>
        Information about your computer hardware and software may be
        automatically collected by Shalom Friend. This information can
        include: your IP address, browser type, domain names, access times and
        referring website addresses. This information is used for the operation
        of the service, to maintain quality of the service, and to provide
        general statistics regarding use of the Shalom Friend website.
      </p>
      <h3>Links</h3>
      <p>
        This website contains links to other sites. Please be aware that we are
        not responsible for the content or privacy practices of such other
        sites. We encourage our users to be aware when they leave our site and
        to read the privacy statements of any other site that collects
        personally identifiable information.
      </p>
      <h3>Security of your Personal Information</h3>
      <p>
        Shalom Friend secures your personal information from
        unauthorized access, use, or disclosure. Shalom Friend uses the
        following methods for this purpose:
      </p>
      <h3>SSL Protocol</h3>
      <p>
        When personal information (such as a credit card number) is transmitted
        to other websites, it is protected through the use of encryption, such
        as the Secure Sockets Layer (SSL) protocol. We strive to take
        appropriate security measures to protect against unauthorized access to
        or alteration of your personal information. Unfortunately, no data
        transmission over the Internet or any wireless network can be guaranteed
        to be 100% secure. As a result, while we strive to protect your personal
        information, you acknowledge that: (a) there are security and privacy
        limitations inherent to the Internet which are beyond our control; and
        (b) security, integrity, and privacy of any and all information and data
        exchanged between you and us through this Site cannot be guaranteed.
      </p>
      <h3>Children Under Thirteen</h3>
      <p>
        Shalom Friend does not knowingly collect personally identifiable
        information from children under the age of thirteen. If you are under
        the age of thirteen, you must ask your parent or guardian for permission
        to use this website.
      </p>
      <h3>External Data Storage Sites</h3>
      <p>
        We may store your data on servers provided by third party hosting
        vendors with whom we have contracted.
      </p>
      <h3>Changes to this Statement</h3>
      <p>
        Shalom Friend reserves the right to change this Privacy Policy
        from time to time. We will notify you about significant changes in the
        way we treat personal information by sending a notice to the primary
        email address specified in your account, by placing a prominent notice
        on our site, and/or by updating any privacy information on this page.
        Your continued use of the Site and/or Services available through this
        Site after such modifications will constitute your: (a) acknowledgment
        of the modified Privacy Policy; and (b) agreement to abide and be bound
        by that Policy.
      </p>
      <h3>Contact Information</h3>
      <p>
        Shalom Friend welcomes your questions or comments regarding this
        Statement of Privacy. If you believe that Shalom Friend has not
        adhered to this Statement, please contact Shalom Friend at:
        Shoresh David Messianic Synagogue of Brandon Inc. 445 Lithis Pinecrest
        rd Brandon, Florida 33511 Email Address: rabbi@1messiah.org Telephone
        number: 813-699-4155 Effective as of July 09, 2018
      </p>
    </div>
  </React.Fragment>
);
